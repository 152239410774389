import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'components/Link';
import { LOGIN_PAGE, SIGNUP_PAGE } from 'constants/pages';
import { Transition } from '@headlessui/react';
import useViewer from 'hooks/useViewer';
import firebase from 'firebase';
import { auth } from 'services/client/firebase';
import * as Sentry from '@sentry/nextjs';
import Google from 'svg/Google';

interface FloatingBoxProps {
  next?: string;
  returnToCurrentPage?: boolean;
  showImmediately?: boolean;
  delay?: number;
}

export default function FloatingBox({
  next,
  returnToCurrentPage = true,
  showImmediately = false,
  delay = 5000,
}: FloatingBoxProps) {
  const [isShowing, setIsShowing] = useState(showImmediately);
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();
  const { viewer } = useViewer();
  const isAnonymous = !viewer || viewer.isAnonymous;

  const loginHref = returnToCurrentPage
    ? `${LOGIN_PAGE}?next=${encodeURIComponent(router.asPath)}`
    : next
    ? `${LOGIN_PAGE}?next=${encodeURIComponent(next)}`
    : LOGIN_PAGE;
  const signupHref = returnToCurrentPage
    ? `${SIGNUP_PAGE}?next=${encodeURIComponent(router.asPath)}`
    : next
    ? `${SIGNUP_PAGE}?next=${encodeURIComponent(next)}`
    : SIGNUP_PAGE;

  useEffect(() => {
    if (!showImmediately && isAnonymous) {
      const timer = setTimeout(() => setIsShowing(true), delay);
      return () => clearTimeout(timer);
    }
  }, [showImmediately, delay, isAnonymous]);

  const handleGoogleSignIn = async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);
      const provider = new firebase.auth.GoogleAuthProvider();
      await auth.signInWithPopup(provider);
      // The component will automatically hide due to viewer state change
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isAnonymous) {
    return null;
  }

  return (
    <Transition
      show={isShowing}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed bottom-4 left-4 z-50 w-80 rounded-lg bg-palette-black-3 p-4 shadow-[0_0_10px_rgba(0,122,255,0.5)]">
        <h3 className="styled-font mb-2 text-lg font-semibold text-white">
          Log In or Create Account
        </h3>
        <p className="mb-4 text-sm text-gray-300">
          Unlock access to our free tools by creating an account.
        </p>
        <div className="flex flex-col gap-2">
          <div className="flex space-x-2">
            <Link
              href={loginHref}
              className="hover:bg-brand-primary-dark flex-1 rounded bg-brand-primary px-4 py-2 text-center text-sm font-semibold text-white transition-colors"
            >
              Log In
            </Link>
            <Link
              href={signupHref}
              className="flex-1 rounded bg-white px-4 py-2 text-center text-sm font-semibold text-brand-primary transition-colors hover:bg-gray-100"
            >
              Create Account
            </Link>
          </div>

          <div className="relative py-1">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-600"></div>
            </div>
            <div className="relative flex justify-center">
              <span className="bg-palette-black-3 px-2 text-xs text-gray-500">
                Or continue with
              </span>
            </div>
          </div>

          <button
            onClick={handleGoogleSignIn}
            disabled={isLoading}
            className="flex w-full items-center justify-center space-x-2 rounded border border-gray-600 bg-palette-black-3 px-4 py-2 text-sm font-semibold text-white transition-colors hover:bg-palette-black-4 disabled:opacity-50"
          >
            <Google className="h-5 w-5" />
            <span>{isLoading ? 'Signing in...' : 'Continue with Google'}</span>
          </button>
        </div>
      </div>
    </Transition>
  );
}
